<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="8" sm="10">
        <v-card v-if="loading" elevation="3">
          <v-card-title></v-card-title>
          <v-card-text>
            <v-sheet>
              <v-skeleton-loader
                type="card-heading, divider, list-item-three-line, list-item-three-line, divider, list-item-three-line, list-item-three-line, divider, list-item-three-line, actions"
              ></v-skeleton-loader>
            </v-sheet>
          </v-card-text>
        </v-card>

        <equipment-form
          v-else
          :title="title"
          :value="equipment"
          @cancel="onClose"
          @create="onSubmit"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EquipmentForm from "@/components/forms/EquipmentForm";
import { mapActions } from "vuex";
import EventBus from "@/util/eventbus";

export default {
  name: "EquipmentView",
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  components: { EquipmentForm },
  data: () => ({
    equipment: null,
    loading: true,
    attrs: {
      boilerplate: true,
      elevation: 2,
    },
  }),
  computed: {
    title() {
      return this.$route.params["id"]
        ? `Editar equipo ${this.$route.params["id"]}`
        : "Nuevo Equipo";
    },
  },
  created() {
    const param = this.$route.params["id"];
    if (param) {
      this.$store.commit("LOADER", true);
      this.getEquipment(param)
        .then((eq) => {
          this.equipment = eq;
          this.loading = false;
        })
        .finally(() => {
          this.$store.commit("LOADER", false);
        });
    } else {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions("equipment", [
      "editEquipment",
      "getEquipment",
      "createEquipment",
    ]),
    onSubmit(item) {
      this.$store.commit("LOADER", true);
      /* Edit item */
      if (item.code) {
        this.editEquipment({ id: item.code, equipment: item })
          .then(() => {
            EventBus.$emit("SHOW_SNACKBAR", {
              text: "Los cambios para el equipo se han guardado",
              color: "success",
            });
            this.$router.push({ name: "eq.list" });
          })
          .finally(() => this.$store.commit("LOADER", false));
      } else {
        /* New Item */
        this.createEquipment(item)
          .then(() => {
            EventBus.$emit("SHOW_SNACKBAR", {
              text: "Se ha creado el equipo",
              color: "success",
            });
            this.$router.push({ name: "eq.list" });
          })
          .finally(() => this.$store.commit("LOADER", false));
      }
    },
    onClose() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
</style>