<template>
  <div>
    <div class='d-flex flex-column flex-md-row'>
      <v-text-field v-model='component.trademark'
                    :readonly="readonly"
                    :rules='rules.trademark'
                    label='Marca'
                    outlined/>
      <v-text-field v-model='component.model'
                    :readonly="readonly"
                    :rules='rules.model'
                    class='ml-md-2'
                    label='Modelo'
                    outlined/>
      <v-text-field v-model='component.serie'
                    :readonly="readonly"
                    :rules='rules.serie'
                    class='ml-md-2'
                    label='Serie'
                    outlined/>
    </div>
    <div class='d-flex flex-column flex-md-row'>
      <v-text-field v-model='component.voltage'
                    :readonly="readonly"
                    :rules='rules.voltage'
                    label='Voltaje'
                    outlined
                    type="number"/>
      <v-text-field v-model='component.amperage'
                    :readonly="readonly"
                    :rules='rules.amperage'
                    class='ml-md-2'
                    label='Amperaje'
                    outlined
                    type='number'/>
    </div>
    <div v-if="component.componentType !== 2" class='d-flex flex-column flex-md-row'>
      <v-text-field v-model='component.highPressure'
                    :readonly="readonly"
                    :rules='rules.highPressure'
                    label='Presión Alta'
                    outlined
                    type='number'/>
      <v-text-field v-model='component.lowPressure'
                    :readonly="readonly"
                    :rules='rules.lowPressure'
                    class='ml-md-2'
                    label='Presión Baja'
                    outlined
                    type='number'/>
    </div>
    <div class='d-flex flex-column flex-md-row'>
      <v-text-field v-model='component.area'
                    :readonly="readonly"
                    label='Area'
                    outlined/>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentForm",
  props: {
    component: { type: Object, required: true },
    readonly: { type: Boolean, default: false },
  },
  data: () => ({
    rules: {
      model: [v => !!v || 'Ingrese el modelo'],
      trademark: [v => !!v || 'Ingrese la marca'],
      serie: [v => !!v || 'Ingrese el número de serie'],
      voltage: [v => !!v || 'Ingrese el voltage'],
      amperage: [v => !!v || 'Ingrese el amperage'],
      lowPressure: [v => !!v || 'Ingrese la presión baja del equipo'],
      highPressure: [v => !!v || 'Ingrese la presión alta del equipo'],
    }
  }),
}
</script>

<style scoped>

</style>