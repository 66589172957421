<template>
  <v-card>
    <v-card-title class='primary white--text'>{{ title }}</v-card-title>
    <v-card-text>
      <v-form ref='form' :value='valid' lazy-validation>
        <form-section title='Datos generales'/>
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            md="6"
            class="flex-grow-1 flex-shrink-1"
          >
            <v-text-field v-if="readonly || (value && value.code)"
                          :value="value.client.name"
                          readonly
                          outlined
                          label="Cliente"
                          class="pr-md-1"/>
            <v-select v-else v-model="client"
                      :items="clients"
                      :rules="rules.client"
                      :loading="loadingClients"
                      item-text="name"
                      item-value="id"
                      label="Cliente"
                      class="pr-md-1"
                      outlined
                      @change="clientId => _loadOffices(clientId)"/>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="flex-grow-1 flex-shrink-1"
          >
            <v-text-field v-if="readonly || (value && value.code)"
                          :value="value.office.name"
                          readonly
                          class="pl-md-1"
                          label="Oficina"
                          outlined/>
            <v-select v-else v-model="office"
                      :items="offices"
                      :loading="loadingOffices"
                      :rules="rules.office"
                      item-text="name"
                      item-value="id"
                      label="Oficina"
                      class="pl-md-1"
                      outlined/>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="6"
            class="flex-grow-1 flex-shrink-1"
          >
            <v-text-field v-model='trademark'
                          :rules='rules.trademark'
                          :readonly="readonly"
                          label='Marca'
                          class="pr-md-1"
                          outlined/>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="flex-grow-1 flex-shrink-1"
          >
            <v-text-field v-if="readonly || (value && value.code)"
                          :value="value.equipmentType.name"
                          readonly
                          class="pl-md-1"
                          label="Tipo de equipo"
                          outlined/>
            <v-select v-else
                      v-model="equipmentType"
                      :items="equipmentTypes"
                      :rules="rules.equipmentType"
                      :loading="loadingTypes"
                      item-text="name"
                      item-value="id"
                      label="Tipo de equipo"
                      class="pl-md-1"
                      outlined/>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            cols="12"
            md="6"
            class="flex-grow-1 flex-shrink-1"
          >
            <v-text-field v-model='refrigerant'
                          :rules='rules.refrigerant'
                          :readonly="readonly"
                          label='Refrigerante'
                          class="pr-md-1"
                          outlined/>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="flex-grow-1 flex-shrink-1"
          >
            <v-text-field v-model='capacity'
                          :rules='rules.capacity'
                          :readonly="readonly"
                          label='Capacidad'
                          class="pl-md-1"
                          min='0'
                          step='0.50'
                          type='number'
                          outlined/>
          </v-col>
        </v-row>
        <div v-if="kind === 1">
          <form-section class="pt-0" title='Datos del Evaporador'/>
          <component-form :component="evaporator" :readonly="readonly"/>
          <form-section class="pt-0" title='Datos del Condensador'/>
          <component-form :component="condenser" :readonly="readonly"/>
        </div>
        <div v-else-if="kind === 2">
          <form-section class="pt-0" title='Datos del Equipo'/>
          <component-form :component="pack" :readonly="readonly"/>
        </div>
        <div v-else-if="kind === 3">
          <form-section class="pt-0" title='Datos del Condensador'/>
          <component-form :component="condenser" :readonly="readonly"/>
        </div>
        <div v-else-if="kind === 4">
          <form-section class="pt-0" title='Datos del Evaporador'/>
          <component-form :component="evaporator" :readonly="readonly"/>
        </div>
      </v-form>

      <v-card-actions v-if="!readonly">
        <v-btn depressed outlined @click='handleCancelForm'>
          Cancelar
        </v-btn>
        <v-spacer/>
        <v-btn class='primary' @click='handleSubmitForm'>
          Guardar
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer/>
        <v-btn depressed outlined @click='handleCancelForm'>
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import ComponentForm from "@/components/forms/ComponentForm";
import FormSection from "@/components/widgets/Section";

export default {
  name: 'EquipmentForm',
  components: {FormSection, ComponentForm},
  props: {
    title: {type: String, default: 'Nuevo Equipo'},
    value: {type: Object, default: null},
    readonly: {type: Boolean, default: false},
    cancel: {type: Boolean, default: false}
  },
  data: () => ({
    valid: false,
    // collections
    offices: [],
    equipmentTypes: [],
    clients: [],

    // fields
    client: null,
    office: null,
    trademark: null,
    equipmentType: null,
    refrigerant: null,
    capacity: null,

    loadingOffices: false,
    loadingClients: false,
    loadingTypes: false,

    condenser: {componentType: 1},
    evaporator: {componentType: 2},
    pack: {componentType: 3},

    rules: {
      client: [v => !!v || 'Seleccione un cliente'],
      office: [v => !!v || 'Seleccione una oficina'],
      trademark: [v => !!v || 'Ingrese el la marca'],
      refrigerant: [v => !!v || 'Ingrese refrigerante'],
      equipmentType: [v => !!v || 'Ingrese el tipo'],
      capacity: [v => !!v || 'Ingrese la capacidad'],
    }
  }),
  computed: {
    kind() {
      if (this.equipmentType === null) return 1;
      if (this.equipmentTypes === null) return 1;

      for (let index = 0; this.equipmentTypes.length; index++) {
        if (this.equipmentTypes[index].id === this.equipmentType) {
          return this.equipmentTypes[index].kind
        }
      }

      return 1
    },
  },
  created() {
    if (!this.readonly && this.value === null) {
      this._fetchClients()
      this._fetchEquipmentTypes()
      // this._loadOffices(this.value.client.id)
    }

    if (this.value !== null) {
      this.trademark = this.value.trademark
      this.refrigerant = this.value.refrigerant
      this.capacity = this.value.capacity

      this.client = this.value.client.id
      this.clients.push(this.value.client)

      this.office = this.value.office.id
      this.offices.push(this.value.office)

      this.equipmentType = this.value.equipmentType.id
      this.equipmentTypes.push(this.value.equipmentType)

      if (this.value.components) {
        this.value.components.forEach(item => {
          switch (item.componentType) {
            case 1:
              this.condenser = Object.assign({}, item);
              break;
            case 2:
              this.evaporator = Object.assign({}, item);
              break;
            case 3:
              this.pack = Object.assign({}, item);
              break;
          }
        })
      }
    } else {
      // 
    }
  },
  methods: {
    _loadOffices(clientId) {
      this.office = null
      this.loadingOffices = true
      this.$axios.get(`/api/client/${clientId}/office?PageSize=1000`)
        .then(({data}) => this.offices = data)
        .finally(() => this.loadingOffices = false)
    },
    _fetchClients() {
      this.loadingClients = true
      this.$axios.get(`/api/library/clients`)
        .then(({data}) => this.clients = data)
        .finally(() => this.loadingClients = false)
    },
    _fetchEquipmentTypes() {
      this.loadingTypes = true
      this.$axios.get(`/api/library/equipment/types`)
        .then(({data}) => this.equipmentTypes = data)
        .finally(() => this.loadingTypes = false)
    },
    handleSubmitForm() {
      if (!this.$refs.form.validate()) return;
      const kind = this.kind

      let equipment = {
        trademark: this.trademark,
        equipmentType: this.equipmentType,
        client: this.client,
        refrigerant: this.refrigerant,
        capacity: this.capacity,
        office: this.office,
      }

      if (this.value) equipment.code = this.value.code

      if (kind === 1)
        equipment.components = [this.condenser, this.evaporator]
      else if (kind === 2)
        equipment.components = [this.pack]
      else if (kind === 3)
        equipment.components = [this.condenser]
      else
        equipment.components = [this.evaporator]

      this.$emit('create', equipment)
    },
    handleCancelForm() {
      this.$router.go(-1);
    },
  }
}
</script>

<style lang='sass' scoped>
</style>