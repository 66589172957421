<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card elevation="3">

        <v-card elevation="3">
          <v-card-text flat elevation="3">
            <v-row no-gutters>
              <v-col
                cols="12"
                md="2"
                class="flex-grow-1 flex-shrink-1"
              >
                <v-text-field v-model='newFilter.code'
                              label='Código'
                              class="pr-md-1"
                              outlined
                              dense
                              @keyup.enter="fetchRecords(newFilter)"/>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="flex-grow-1 flex-shrink-1"
              >
                <v-text-field v-model='newFilter.clientName'
                              label='Cliente'
                              class="pr-md-1"
                              outlined
                              dense
                              @keyup.enter="fetchRecords(newFilter)"/>
              </v-col>
              <v-col
                cols="12"
                md="3"
                class="flex-grow-1 flex-shrink-1"
              >
                <v-select
                  v-model="newFilter.equipmentType"
                  :items="equipmentTypesList"
                  item-text="name"
                  item-value="id"                  
                  label="Tipo de Equipo"
                  class="pr-md-1"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="flex-grow-1 flex-shrink-1"
              >
                <v-text-field v-model='newFilter.trademarkName'
                              label='Marca'
                              class="pr-md-1"
                              outlined
                              dense
                              @keyup.enter="fetchRecords(newFilter)"/>
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="flex-grow-1 flex-shrink-1"
              >
                <v-text-field v-model='newFilter.refrigerant'
                              label='Refrigerante'
                              class="pr-md-1"
                              outlined
                              dense
                              @keyup.enter="fetchRecords(newFilter)"/>
              </v-col>
              </v-row>
              <v-row no-gutters>
              <v-spacer></v-spacer>
              <v-btn
                    color="default"
                    depressed
                    outlined
                    class="mr-2"
                    @click="clearFilter()"
                  >
                    Limpiar
                  </v-btn>
              <v-btn
                    color="primary"
                    depressed
                    @click="fetchRecords(newFilter)"
                  >
                    Buscar
                  </v-btn>
            </v-row>                       
          </v-card-text>
          </v-card>
          
          <v-toolbar color="primary" flat>
            <v-spacer></v-spacer>
            <v-btn icon @click="handleRefreshItem">
              <v-icon color="white">mdi-refresh</v-icon>
            </v-btn>
            <v-btn icon @click="addEquipment">
              <v-icon color="white">mdi-plus</v-icon>
            </v-btn>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="subtitle-1">
                  ¿Estás seguro de que quieres eliminar este equipo?
                </v-card-title>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn color="blue darken-1" text @click="closeDelete">
                    Cancel
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">
                    OK
                  </v-btn>
                  <v-spacer/>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-card-text>
            <v-dialog v-model="dialog" max-width="800">
              <equipment-form v-if="dialog === true"
                              readonly
                              title="Detalles del equipo"
                              :value="editedItem"
                              @cancel="dialog=false"/>
            </v-dialog>
            <v-data-table
              :footer-props="{
                showFirstLastPage: true,
                'items-per-page-options': [5, 10, 15, 50],
              }"
              :headers="headers"
              :items="equipments"
              :loading="loadingItems"
              :options.sync="options"
              :server-items-length="serverItemsLength"
              disable-sort
            >
              <template v-slot:[`item.equipmentType`]="{ item }">
                {{ equipmentTypes[item.equipmentType - 1].value }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                           class="ma-1"
                           color="green accent-5"
                           depressed
                           fab
                           x-small
                           v-on="on"
                           @click="showViewDialog(item)">
                      <v-icon color="white" small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Detalles</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                           class="ma-1"
                           color="light-blue darken-3"
                           depressed
                           fab
                           x-small
                           v-on="on"
                           @click="goToEditItem(item)">
                      <v-icon color="white" small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs"
                           class="ma-1"
                           color="red"
                           depressed
                           fab
                           x-small
                           v-on="on"
                           @click="showDeleteDialog(item)">
                      <v-icon color="white" small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>

              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions, mapState} from "vuex"
import EquipmentForm from "@/components/forms/EquipmentForm"
import EventBus from "@/util/eventbus"

export default {
  name: "EquipmentList",
  components: { EquipmentForm },
  data() {
    return {
      editedIndex: -1,
      dialog: false,
      dialogDelete: false,
      loadingItems: false,
      serverItemsLength: 0,
      options: {},
      editedItem: {
        client: {},
        condenser: {},
        evaporator: {},
      },
      defaultItem: {
        client: {},
        condenser: {},
        evaporator: {},
      },
      headers: [
        {
          text: "Código",
          value: "code",
        },
        {
          text: "Cliente",
          value: "client.name",
        },
        {
          text: "Tipo de equipo",
          value: "equipmentType.name",
        },
        {
          text: "Marca",
          value: "trademark",
        },
        {
          text: "Refrigerante",
          value: "refrigerant",
        },
        {
          text: "Capacidad",
          value: "capacity",
        },
        {
          text: "Acciones",
          value: "actions",
          width: 40*3+32
        },
      ],
      equipmentTypesList: [],
      newFilter: {
        page: 1,
        PageSize: null,
        code: null,
        clientName: null,
        trademarkName: null,
        equipmentType: null,
        refrigerant: null
      },
    }
  },
  computed: {
    ...mapState("equipment", ["equipments"]),
    ...mapState("library", ["equipmentTypes"]),
  },
  watch: {
    options: {
      handler() {
        const { page, itemsPerPage } = this.options
        this.newFilter.PageSize = itemsPerPage
        this.newFilter.page = page
        this.fetchRecords(this.newFilter)
      },
      deep: true,
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },
  created() {
      this._fetchEquipmentTypes()    
  },
  methods: {
    ...mapActions("equipment", ["fetchEquipments", "deleteEquipment",]),
    fetchRecords(newFilter) {
      this.loadingItems = true
      this.fetchEquipments(newFilter)
        .then(total => this.serverItemsLength = total.totalItems)
        .finally(() => (this.loadingItems = false))
    },
    clearFilter() {
      this.loadingItems = true;
      this.newFilter.code = null
      this.newFilter.clientName = null
      this.newFilter.trademarkName = null
      this.newFilter.equipmentType = null
      this.newFilter.refrigerant = null
      this.fetchRecords(this.newFilter)
      this._fetchEquipmentTypes()
      this.loadingItems = false;
    },
    _fetchEquipmentTypes() {
      this.loadingTypes = true
      this.$axios.get(`/api/library/equipment/types`)
        .then(({data}) => this.equipmentTypesList = data)
        .finally(() => this.loadingTypes = false)
    },
    handleRefreshItem() {
      this.fetchRecords(this.newFilter)
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    showViewDialog(item) {
      this.editedIndex = this.equipments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    showDeleteDialog(item) {
      this.editedIndex = this.equipments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.deleteEquipment({ item: this.editedItem, position: this.editedIndex })
        .then(() => {
          this.closeDelete()
          EventBus.$emit("SHOW_SNACKBAR", {
            text: 'Equipo eliminado',
            color: 'success',
          })
        })
    },
    addEquipment() {
      this.$router.push({ name: 'eq.create' })
    },
    goToEditItem(item) {
      this.$router.push({ name: 'eq.edit', params: { id: item.code } })
    },
    gotToUploadEquipments() {
      this.$router.push({ name: 'eq.upload' })
    },
  },
}
</script>

<style scoped></style>
